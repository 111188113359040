@import "styles/globals.scss";

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #5bc0be;
}

.title {
  color: white;
  font-size: 4rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0;
  @include mobile() {
    font-size: 3rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}

.projectCardScreenShots {
  width: 50%;
  display: flex;
}

.projectCard {
  flex: auto;
  display: flex;
  background: #0b132b;
  border-radius: 1rem;
  width: 80%;
  margin: 3rem 0;
}

.projectCardDetails {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: start;
  padding-right: 1rem;
}
.projectDetailsTitle {
  color: white;
  font-size: 2rem;
  margin: 0;
  margin-top: 1rem;
}
.tableData {
  vertical-align: text-top;
}
.projectDetails {
  color: white;
  font-size: 1rem;
  margin: 0;
}

.button {
  font-size: 2rem;
  font-family: inherit;
  color: #0b132b;
  width: fit-content;
  padding: 0.8rem 1.6rem;
  border-width: 3px;
  border-radius: 10px;
  border-style: solid;
  border-color: #0b132b;
  cursor: pointer;
  font-weight: 500;
  transition: 0.5s;
  margin: 2rem;
  background: #5bc0be;
}
.button:hover {
  color: #5bc0be;
  background-image: linear-gradient(135deg, #0b132b, #0b132b);
  background-clip: border-box;
  -webkit-background-clip: border-box;
}

.buttonsLayout {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: auto;
}

.gridDisplay {
  display: flex;
  width: 100%;
  margin: auto;
  padding: 3rem;
  flex-flow: wrap;
  justify-content: space-around;
  grid-row-gap: 2rem;
  row-gap: 2rem;
  @include mobile() {
    padding: 0;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    padding-bottom: 3rem;
  }
}

.thumbnailLayout {
  height: 8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  background: #0b132b;
  border-radius: 20px;
  width: 27vw;
  padding: 0 1rem;
  @include mobile-tablet() {
    width: 40vw;
  }
  @include mobile() {
    width: 90%;
    height: 6rem;
  }
}

.thumbnailImage {
  width: 5rem;
  height: 5rem;
  margin-right: 1rem;
  object-fit: cover;
  border-radius: 20px;
  background: white;
  @include mobile() {
    width: 4rem;
    height: 4rem;
  }
}

.thumbnailTitle {
  text-align: start;
  color: white;
  font-size: 1.5rem;
  margin: 0;
  @include mobile() {
    font-size: 1rem;
  }
}
