@import "styles/globals.scss";

.aboutLayout {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #0b132b;
  @include mobile() {
    flex-direction: column;
    overflow: scroll;
  }
}

.aboutMe {
  background-color: #5bc0be;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 98vh;
  width: 25%;
  border-top: 0;
  @include mobile() {
    position: relative;
    width: 100%;
    height: 50%;
  }
}

.title {
  font-size: 3rem;
  text-transform: capitalize;
  font-weight: 500;
  margin: 2rem 0 2rem 0;
  color: white;
  line-height: 100%;
  @include mobile-tablet() {
    font-size: 2.5rem;
  }
  @include mobile() {
    font-size: 2rem;
    text-align: center;
    margin-top: 0;
  }
}

.contentLayout {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
}

.image {
  width: 100%;
  object-fit: cover;
  object-position: center center;
  opacity: 1;
  transition: opacity 500ms ease 0s;
  box-shadow: 0 5px 15px -8px rgb(0 0 0 / 24%), 0 8px 10px -5px rgb(0 0 0 / 20%);
  place-self: center;
  margin: 1rem auto auto auto;
  background-color: #0b132b;
  padding: 1rem 0;
  @include mobile() {
    padding: 0;
    margin: 0;
    height: -webkit-fill-available;
  }
}

.contentAboutMe {
  font-size: 1.5rem;
  margin: 0;
  text-align: start;
  color: white;
  @include mobile-tablet() {
    font-size: 1rem;
  }
  @include mobile() {
    font-size: 0.75rem;
    text-align: center;
  }
}

.anchor {
  color: #5bc0be;
  font-weight: 500;
}
.columnLayout {
  place-self: center;
  height: 100%;
  width: 100%;
  padding-left: 3rem;
  @include mobile() {
    padding: 1rem;
    height: auto;
  }
}

.buttonResume {
  margin-top: auto;
  font-size: 2vw;
  color: #0b132b;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  border-width: 3px;
  border-radius: 10px;
  border-style: solid;
  border-color: #0b132b;
  cursor: pointer;
  font-weight: 500;
  transition: 0.5s;
  text-align: center;

  @include mobile() {
    width: 50%;
    position: absolute;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    font-size: 1rem;
    color: #5bc0be;
    border-color: #5bc0be;
    opacity: 0.8;
  }
}
.buttonResume:hover {
  color: #0b132b;
  background-image: linear-gradient(135deg, #5bc0be, #5bc0be);
  background-clip: border-box;
  -webkit-background-clip: border-box;
}
