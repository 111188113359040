@import "styles/globals.scss";

.timelineLayout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #0b132b;
}

.title {
  color: white;
  font-size: 4rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  margin: 2rem;

  @include mobile() {
    font-size: 2rem;
    margin: 1rem;
  }
}

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  width: 100%;
  padding: 2rem 0;
  margin: 0 auto;
  background: #5bc0be;
}

.timelineHeader {
  margin: 0;
  padding: 10px 15px;
  background-color: #0b132b;
  color: white;
  position: relative;
  border-radius: 6px;
  height: fit-content;
  margin-bottom: auto;
  @include mobile() {
    font-size: 1rem;
  }
}

.timelineHeaderLayoutRight {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 500;
}

.timelineHeaderLayoutLeft {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;
  align-items: center;
  font-weight: 500;
}

.descriptionLeft {
  text-align: end;
  font-size: 1.5rem;
  color: #0b132b;
  font-weight: 500;
}

.descriptionRight {
  font-size: 1.5rem;
  color: #0b132b;
  font-weight: 500;
}

.duration {
  margin: 0;
  height: min-content;
  align-items: center;
  background-color: #0b132b;
  padding: 5px 15px;
  border-radius: 6px;
  font-weight: 400;
  @include mobile() {
    font-size: 0.75rem;
  }
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: #0b132b;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
  color: white;
}

/* The circles on the timeline */
.container::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: #5bc0be;
  border: 4px solid #0b132b;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #0b132b;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent #0b132b transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -16px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 1000px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent #0b132b transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }

  .timelineHeaderLayoutLeft {
    flex-direction: row-reverse;
    text-align: start;
  }

  .descriptionLeft {
    text-align: start;
    font-size: 1rem;
  }
  .descriptionRight {
    font-size: 1rem;
  }
}
