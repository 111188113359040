@import "styles/globals.scss";

.modal {
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  display: flex;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
  animation: opac 0.3s;
}

@keyframes opac {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modalHeader {
  padding: 0 20px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  background-color: #5bc0be;
  margin: auto;
  padding: 5px;
  width: 80%;
  height: 80%;
  border-radius: 20px;
  @include mobile-tablet() {
    width: 90%;
    height: 90%;
  }
  @include mobile() {
    width: 95%;
    height: 95%;
  }
  @include height-small() {
    width: 90%;
    height: 90%;
  }
}
.close {
  background-color: transparent;
  border-radius: 20px;
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
  border: none;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.projectTitle {
  color: white;
  font-size: 1.5rem;
  margin: 0;
  @include mobile() {
    font-size: 7vw;
  }
}

.projectCard {
  height: inherit;
  display: flex;
  flex: auto;
  align-items: center;
  background-color: #0b132b;
  color: white;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  @include mobile-tablet() {
    flex-direction: column;
    overflow: auto;
  }
}

.projectCardScreenShots {
  width: 50%;
  @include mobile-tablet() {
    width: 100%;
    margin-top: 1rem;
  }
}
.projectCardDetails {
  width: 50%;
  @include mobile-tablet() {
    width: 90%;
  }
  @include height-small() {
    height: inherit;
    overflow: scroll;
  }
}

.tableRow {
  @include mobile() {
    display: flex;
    flex-direction: column;
  }
}
