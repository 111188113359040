@import "styles/globals.scss";

.nameLayout {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #0b132b;
  padding-left: 10%;
  transition: 0.5s;
}

.intro {
  font-size: 4rem;
  font-weight: 500;
  color: #fff;
  margin: 0;
  @include mobile-tablet() {
    font-size: 3rem;
  }
  @include mobile() {
    font-size: 2rem;
  }
}

.name {
  color: #6fffe9;
}

.knowMore {
  font-family: inherit;
  font-size: 2rem;
  color: #5bc0be;
  width: fit-content;
  margin-right: auto;
  padding: 1rem 1.6rem;
  border-width: 3px;
  border-radius: 10px;
  border-style: solid;
  border-color: #5bc0be;
  cursor: pointer;
  font-weight: 500;
  transition: 0.5s;
  background-color: transparent;
  margin: 1rem 0;
  @include mobile-tablet() {
    font-size: 1.5rem;
  }
  @include mobile() {
    font-size: 1rem;
    padding: 0.5rem 0.8rem;
  }
}
.knowMore:hover {
  color: #0b132b;
  background-image: linear-gradient(135deg, #5bc0be, #5bc0be);
  background-clip: border-box;
  -webkit-background-clip: border-box;
}
