@import "styles/globals.scss";

.layout {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #5bc0be;
}

.topLayout {
  display: flex;
  flex-direction: column;
  background-color: #0b132b;
  width: 100%;
  height: 85%;
}

.label1 {
  color: white;
  font-size: 4rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0;
  @include height-small() {
    margin-top: 1rem;
  }
  @include mobile() {
    font-size: 2rem;
  }
}

.label2 {
  color: white;
  font-size: 2rem;
  text-align: center;
  word-wrap: break-word;
  @include height-small() {
    margin: 0;
  }
  @include mobile() {
    font-size: 1rem;
  }
}

.anchor {
  color: #5bc0be;
}

.labelsLayout {
  margin: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.bottomLayout {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: auto;
  flex-wrap: wrap;
}

.bioLinks {
  display: flex;
  flex-direction: row;
  grid-gap: 2rem;
  gap: 2rem;
  padding: 2rem;
  @include mobile() {
    grid-gap: 1rem;
    gap: 1rem;
    padding: 1rem;
  }
}

.linkIcon {
  width: 40px;
  height: 40px;
  @include mobile() {
    width: 30px;
    height: 30px;
  }
}

.buttonGoToTop {
  font-size: 2rem;
  color: #0b132b;
  width: fit-content;
  padding: 0.8rem 1.6rem;
  border-width: 3px;
  border-radius: 10px;
  border-style: solid;
  border-color: #0b132b;
  cursor: pointer;
  font-weight: 500;
  transition: 0.5s;
  margin-left: auto;
  margin-right: 2rem;
  @include mobile() {
    padding: 0.4rem 0.8rem;
    font-size: 1rem;
    margin-right: 1rem;
  }
}
.buttonGoToTop:hover {
  color: #5bc0be;
  background-image: linear-gradient(135deg, #0b132b, #0b132b);
  background-clip: border-box;
  -webkit-background-clip: border-box;
}
